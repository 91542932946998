<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {},
}
</script>
 
<style lang = "less" scoped>
</style>